import { CurrentProfile } from 'types/Profile';
import { UserData } from 'types/Auth';

const STORAGE = {
  SCH_TV_PROFILE: 'SCH_TV_PROFILE',
  SET_USER_DATA: 'screen_hits_user_data'
};

const setStorageProfileItem = (profile: CurrentProfile) =>
  localStorage.setItem(STORAGE.SCH_TV_PROFILE, JSON.stringify(profile));

const getProfileFromStorage = () => {
  const profile = localStorage.getItem(STORAGE.SCH_TV_PROFILE);
  if (profile) {
    return JSON.parse(profile || '');
  }
};

const removeProfileDataFromStorage = () => localStorage.removeItem(STORAGE.SCH_TV_PROFILE);

const setUserDataToLocalStorage = (userData: UserData) =>
  localStorage.setItem(STORAGE.SET_USER_DATA, JSON.stringify(userData));


const getUserDataFromLocalStorage = (): string | null =>
  localStorage.getItem(STORAGE.SET_USER_DATA) as unknown as string;


const removeUserDataFromLocalStorage = () =>
  localStorage.removeItem(STORAGE.SET_USER_DATA);

export {
   setStorageProfileItem, 
   getProfileFromStorage, 
   removeProfileDataFromStorage, 
   setUserDataToLocalStorage,
   getUserDataFromLocalStorage,
   removeUserDataFromLocalStorage
 };
