import { useState } from 'react';
import { alpha, Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import colors from 'theme/colors';
import { useAppContext } from 'store';
import NewProfileThumb from 'assets/images/new-profile-thumb.png';
import NavbarDropdownAccounts from './Accounts';

import useIsChild from 'hooks/useProfiles';
import ParentControl from 'components/ParentControl';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import {
  removeUserDataFromLocalStorage,
} from 'helpers/LocalStorage';

const NavbarAccountDropdown: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    state: { selectedProfile, currentProfile },
  } = useAppContext();

  const { checkIsChild } = useIsChild();

  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [parentControl, setParentControl] = useState(false);

  const open = checkIsChild ? !parentControl && Boolean(anchorEl) : Boolean(anchorEl);

  const handleSetParentControl = () => {
    setParentControl((prevState) => !prevState);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (checkIsChild) {
      handleSetParentControl();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => setAnchorEl(null);

  const handleLogout = (): void => {
    handleClose();
    logout();
    removeUserDataFromLocalStorage();
  };

  return (
    <Box>
      <ParentControl isShow={parentControl} setIsShow={handleSetParentControl} />
      <Grid
        container
        wrap={'nowrap'}
        alignItems={'center'}
        spacing={1}
        onClick={handleClick}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        <Grid item>
          <Avatar
            src={selectedProfile.thumbnail ? selectedProfile.thumbnail : NewProfileThumb}
            sx={{
              width: { xs: 24, lg: 35 },
              height: { xs: 24, lg: 35 },
              borderWidth: 3,
              border: 'solid',
              borderColor: colors.primary,
            }}
          />
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography
            color={colors.primary}
            sx={{
              fontSize: { xs: 16, lg: 18 },
            }}
          >
            {currentProfile.profileName}
          </Typography>
        </Grid>
      </Grid>
      <Menu
        id='account-dropdown-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={0}
        sx={{
          root: {
            paper: {
              backgroundColor: alpha(colors.secondary, 0.5),
            },
          },
        }}
      >
        <NavbarDropdownAccounts onClose={() => handleClose()} />
        <MenuItem
          onClick={handleLogout}
          sx={{
            display: 'block',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 1,
          }}
        >
          {t('button.logout')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NavbarAccountDropdown;
