import { Box, Button, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import Logo from 'components/Logo';
import usePageBackground from 'hooks/usePageBackground';
import { BackgroundType, PageType } from 'theme/background';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import colors from 'theme/colors';
import TermsAndConditions from 'components/Dialog/TermsAndConditions';

interface Props {
  children: React.ReactNode;
  isLogo?: boolean;
  background?: string;
}

const AuthLayoutWrapper: React.FC<Props> = ({
  children,
  isLogo = true,
  background,
}: Props): JSX.Element => {
  const location = useLocation();
  const { backgroundURL } = usePageBackground(BackgroundType.IMAGE, PageType.LOGIN);

  const { t } = useTranslation();
  const [isOpenTermsAndCondition, setIsOpenTermsAndCondition] = useState(false);

  const handleCloseTermsAndCondition = () => {
    setIsOpenTermsAndCondition(false);   
  };

  const handleOpenTermsAndCondition = () => {
    setIsOpenTermsAndCondition(true);
  };

  const isMatchingPath = (pathname: string, routePattern: string): boolean => {
    return matchPath(routePattern, pathname) !== null; 
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 1,
        height: '100vh',
        py: 7,
        backgroundColor: '#000',
        backgroundImage: `url(${background ? background : backgroundURL}) !important`,
        backgroundPosition: '50% 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {isLogo && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Logo />
        </Box>
      )}
      <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 3, width: 1 }}>{children}</Box>
      {location.pathname !== appLinks.profiles.link &&
        location.pathname !== appLinks.deactivate.link && 
        location.pathname !== appLinks.newProfile.link &&
        location.pathname !== appLinks.manageProfiles.link &&  
        location.pathname !== appLinks.accountDetails.link &&  
        !isMatchingPath(location.pathname, appLinks.updateProfile.link) &&
        location.pathname !== appLinks.registerReceipt.link &&      
        (
          <Stack
            direction='row'
            sx={{
              zIndex: 10,
              '& a': {
                fontSize: 16,
                px: 1.25,
                py: 0,
                color: colors.primary,
                lineHeight: 0,
                '&:hover': {
                  cursor: 'pointer',
                },
              },
              '& .MuiDivider-root': {
                borderColor: colors.primary,
                height: '20.5px',
              },
            }}
          >
            {location.pathname === appLinks.login.link ? (
              <Button component={NavLink} to={appLinks.register.link}>
               {t('login.singIn')}
              </Button>
            ) : (
              <Button component={NavLink} to={appLinks.login.link}>
                {t('login.button')}
              </Button>
            )}
            <Divider orientation='vertical' flexItem />
            <Button component={NavLink} to={appLinks.forgotPassword.link}>
              {t('login.errors.noRightPassword.buttonText')}
            </Button>
            <Divider orientation='vertical' flexItem />           
            <Button component={NavLink} to='' onClick={handleOpenTermsAndCondition}>
              {t('login.term')}             
            </Button>
            <TermsAndConditions
              isOpen={isOpenTermsAndCondition}
              onClose={() => handleCloseTermsAndCondition()}
            />
          </Stack>
        )}
    </Box>
  );
};

export default AuthLayoutWrapper;
